<template>
  <div v-show="app.ready" class="flex flex-col justify-center md:space-y-20">
    <div
      class="flex-none mx-auto w-full flex items-center justify-center"
      :style="{
        backgroundColor: 'var(--background-color)',
        height: 'calc(var(--logo-max-height) + 5px)', // 5px for padding
      }"
    >
      <img
        v-if="hasCustomLogo"
        :src="whitelabel.get('logoUrl')"
        alt="Company Logo"
        class="whitelabel-logo"
        loading="lazy"
      />
      <PulseIconLogo v-else name="logo" />
    </div>
    <div class="flex-1 flex md:items-center md:pt-0 pb-8 justify-center main-content">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useWhiteLabelStore, useApplicationStore } from '~/store';

const whitelabel = useWhiteLabelStore();
const app = useApplicationStore();

const { hasCustomLogo } = storeToRefs(whitelabel);

useHead({
  link: [
    {
      href: whitelabel.get('faviconUrl'),
      rel: 'icon',
    },
  ],
  title: whitelabel.get('pageTitle') || 'OrderProtection.com - Claims',
});
</script>

<style>
.main-content {
  height: calc(100vh - 100px);
  overflow: hidden;
}
</style>
